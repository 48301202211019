<template>
  <div class="reg-page-wrapper">
    <div class="reg-box">
      <h1 class="form-title">用户注册</h1>
      <el-form class="reg-form" ref="registerForm"
               label-position="top"
               :model="registerForm" :rules="rules">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="registerForm.username" placeholder="请输入用户名" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="registerForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="registerForm.phone" placeholder="请输入手机号" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
<!--          <el-input placeholder="请输入验证码" maxlength="6">-->
<!--            <template slot="append">-->
<!--              <el-button type="success" style="width: 120px;"-->
<!--                         @click="getLoginPhoneCode">{{codeBtnText}}</el-button>-->
<!--            </template>-->
<!--          </el-input>-->
          <sms-input v-model="registerForm.code"
                     :code-type="5" code-form="registerForm"></sms-input>
        </el-form-item>
        <el-form-item label="设置密码" prop="password">
          <el-input v-model="registerForm.password"
                    type="password" placeholder="请设置密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passConfirm">
          <el-input v-model="registerForm.passConfirm"
                    type="password" placeholder="请再次输入密码"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="width: 100%;" :loading="loading"
                 @click="handleSubmit">立即注册</el-button>
      <div class="tip">
        <span style="color: #666;">已有账号，</span>
        <span class="tip-text" @click="toLoginPage">立即登录</span>
      </div>
      <div class="tip-rule">
        注册登录即表示同意用户协议、隐私政策
      </div>
    </div>
  </div>
</template>

<script>
  import {userRegister} from "@/page-home/api/auth";
  import {isMobile} from "@/page-home/utils/validate";
  import SmsInput from "@/page-home/components/SmsInput/index";
  import {setToken} from "@/page-home/utils/storage";
  import {getUserDetail} from "@/page-home/api/home";

  export default {
    name: "Register",
    components: {SmsInput},
    data() {
      const validPhone = (rule, value, callback) => {
        if (isMobile(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
      const validateConfirmPass = (rule, value, callback) => {
        if (value !== this.registerForm.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        loading: false,
        registerForm: {},
        rules: {
          username: [
            {required: true, message: '请输入用户名', trigger: 'blur'},
          ],
          name: [
            {required: true, message: '请输入姓名', trigger: 'blur'},
          ],
          phone: [
            {required: true, message: '请输入中国大陆手机号', trigger: 'blur'},
            {validator: validPhone, trigger: 'blur'}
          ],
          code: [
            {required: true, message: '请输入验证码', trigger: 'blur'},
            {min: 6, message: '请输入正确的验证码', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur'}
          ],
          passConfirm: [
            {required: true, message: '请再次确认密码', trigger: 'blur'},
            {validator: validateConfirmPass, trigger: 'blur'}
          ]
        }
      }
    },

    methods: {
      handleSubmit() {
        if (this.loading) return
        this.$refs['registerForm'].validate(valid => {
          if (valid) {
            this.loading = true
            userRegister(this.registerForm).then(res => {
              setToken('access', 'N-' + res.data.accessToken)
              setToken('refresh', res.data.refreshToken)
              getUserDetail().then(res => {
                this.$store.commit('SET_USER_INFO', res.data)
                this.$message.success('注册成功')
                setTimeout(() => {
                  this.$router.replace('/')
                  this.registerForm = {}
                  this.loading = false
                }, 300)
              })
            }).catch(err => {
              this.loading = false
              this.$message.error(err.msg)
            })
          } else {
            return
          }
        })
      },

      toLoginPage() {
        this.$router.replace('/login')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .reg-page-wrapper {
    height: 100%;
    background-color: #1D2B5A;
    overflow: hidden;
    .reg-box {
      width: 600px;
      margin: 40px auto 10px;
      padding: 20px;
      background-color: #ffffff;
      border-radius: 10px;
    }
  }
  .form-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #000000;
  }
  .reg-form {
    /deep/ {
      .el-form-item__label {
        line-height: 20px;
      }
    }
  }
  .tip {
    margin-top: 20px;
    color: #3a8ee6;
    .tip-text {
      display: inline-block;
      cursor: pointer;
    }
  }
  .tip-rule {
    margin-top: 16px;
    font-size: 12px;
    color: #a1a1a1;
  }
</style>
